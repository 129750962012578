<template>
    <div class="video-img-wrap">
        <div class="img-wrap" @click.stop="imgClick">
            <img :src="posterImgSrc" alt="video poster">
            <div class="play-icon-wrap">
                <i class="el-icon-caret-right"></i>
            </div>
        </div>

        <div ref="videoContainer" class="VP-video-wrap" :class="{ 'VP-video-show': isShow }">
            <video ref="videoPlayer" :src="videoSrc" controls x5-video-player-type="h5"></video>
            <i class="el-icon-close" @click="videoCloseClick"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        posterImgSrc: {
            type: String,
            required: true,
        },
        videoSrc: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            isShow: false,
        }
    },
    methods: {
        imgClick () {
            document.body.appendChild(this.$refs.videoContainer);

            this.isShow = true;
            this.$refs.videoPlayer.currentTime = 0;
            this.$refs.videoPlayer.play();
        },
        videoCloseClick () {
            this.isShow = false
            this.$refs.videoPlayer.pause();
        },
    }
}
</script>

<style lang="scss" scoped>
.video-img-wrap {
    width: 100%;
    height: 100%;

    .img-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
        
        .play-icon-wrap {
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: rgba(130, 130, 130, 0.7);
            border-radius: 50%;
            i {
                color: #fff;
            }
        }       

        
        /* pc端 */
        @media only screen and (min-width: 443px) {
            .play-icon-wrap {
                i {
                    font-size: 3vw;
                    padding: 1vh; 
                }
            }  
        }

        
        /* 手机端 */
        @media only screen and (max-width: 443px) {
            .play-icon-wrap {
                i {
                    font-size: 6vh; 
                    padding: 1vw; 
                }
            }  
        }

    }
}
</style>