<template>
  <div v-if="platform === 'pc'">
    <div class="content" @click="rootClick">
      <!-- 导航条 -->
      <nav-bar></nav-bar>

      <!-- 首屏 + fixed客服 -->
      <div id="home">
        <div class="mode">
          <div class="mode-wrap home-mode-wrap">
            <div class="block">
              <el-carousel
                trigger="click"
                height="860px"
                class="carousel-first"
              >
                <el-carousel-item v-for="item in images" :key="item.id">
                  <div class="zhengti">
                    <div class="zi">
                      <div class="line1">{{ item.line1 }}</div>
                      <div class="line2">{{ item.line2 }}</div>
                      <div class="line3">{{ item.line3 }}</div>
                    </div>
                    <div class="tupian"><img :src="item.url" alt="" /></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- <div class="aiTitle-wrap">
          <div class="aiTitle aiTitle-left">
            {{ $t('TitleA_1') }}
          </div>
          <div class="aiTitle aiTitle-right">
            {{ $t('TitleA_2') }}
          </div>
        </div> -->

        <!-- 扫码+客服 -->
        <a href="#QRcode">
          <div class="rightLink">
            <img src="../assets/erweima.png" />
            <div></div>
            <img src="../assets/erji.png" />
          </div>
        </a>
      </div>
      <!-- 核心技术 id="core" -->
      <div class="mode" id="core">
        <div class="mode-wrap">
          <div>
            <div class="mode-text-wrap">
              <div class="newmodetext">
                <span id="core" class="anchor-span">{{ $t("TitleB") }}</span>
              </div>
              <div class="center">
                <div class="systemtext">
                  {{ $t("ContentB") }}
                </div>
              </div>
              <div class="modebutton-wrap">
                <!-- <button class="modebutton">
                  {{ $t("LearnMore") }}
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="example"
            style="
              display: flex;
              justify-content: space-between;
              margin: 0 auto;
            "
          >
            <div>
              <img
                src="../assets/cheluxitong.png"
                style="width: 22.25rem; height: 22.25rem"
              />
              <div class="modetext-wrap">
                <div class="newmodetext2">
                  {{ $t("MultiSensorFVRSystem") }}
                </div>
                <div class="systemtext2">
                  {{ $t("MultiSource_content") }}
                </div>
              </div>
            </div>
            <div>
              <VideoPlay
                ref="VideoPlay"
                class="VideoPlay"
                :posterImgSrc="posterImgSrc"
                :videoSrc="videoSrc"
                style="width: 22.25rem; height: 22.375rem"
              ></VideoPlay>
              <!-- <img src="../assets/echarts.png" style="width: 22.25rem;height: 22.375rem;"> -->
              <div class="modetext-wrap">
                <div class="newmodetext2">
                  {{ $t("CloudControlPlatform") }}
                </div>
                <div class="systemtext2">
                  {{ $t("CloudControlPlatform_content") }}
                </div>
              </div>
            </div>

            <div>
              <img
                src="../assets/yunjisuan.png"
                style="width: 22.25rem; height: 22.25rem"
              />
              <div class="modetext-wrap">
                <div class="newmodetext2">
                  {{ $t("Hermes_AI_Cloud_Computing") }}
                </div>
                <div class="systemtext2">
                  {{ $t("Hermes_AI_Cloud_Computing_content") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 应用领域 id="application" -->
      <div class="field" id="application">
        <div class="fieldtitle">
          <span id="application" class="anchor-span">{{
            $t("ApplicationFields")
          }}</span>
        </div>
        <div>
          <div class="center">
            <div class="fieldcontent">
              {{ $t("ApplicationFields_Content") }}
            </div>
          </div>
          <div style="display:flex;justify-content: center;margin-top: 20px;">
            <div class="fieldItemMore" style="cursor: pointer;" @click="qingSao">{{ $t("View Solution") }}</div>
            <!-- <div class="fieldItemMore" style="background: #33363a;margin-left: 50px;"></div> -->
            <div class="fieldItemMore" style="background: #33363a;margin-left: 50px;cursor: pointer;" @click="gotoCommerce">
              {{ $t('Come_to_Commerce') }}
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
        <div class="field-img-wrap">
          <div class="fieldItem wurenpeisong" @click.stop="peiSong">
            <div class="mask">{{ $t("Stay Tuned") }}</div>
            <div class="fieldItempadding">
              <div class="fieldItemTitle">
                {{ $t("Unmanned_Delivery_Service") }}
              </div>
              <div class="fieldItemContent">
                {{ $t("Unmanned_Delivery_Service_Content") }}
              </div>
            </div>
          </div>
          <div class="fieldItem wurenjiebo" @click.stop="jieBo">
            <div class="mask1">{{ $t("Stay Tuned") }}</div>
            <div class="fieldItempadding">
              <div class="fieldItemTitle">
                {{ $t("Unmanned_Transfer_Service") }}
              </div>
              <div class="fieldItemContent">
                {{ $t("Unmanned_Transfer_Service_Content") }}
              </div>
            </div>
          </div>
          <div class="fieldItem wurenhuanwei" @click.stop="qingSao">
            <div class="fieldItempadding">
              <div class="fieldItemTitle">
                {{ $t("Unmanned_Cleaning_Service") }}
              </div>
              <div class="fieldItemContent">
                {{ $t("Unmanned_Cleaning_Service_Content") }}
              </div>
              <!-- <div class="fieldItemMore">{{ $t("View Solution") }}</div> -->
            </div>
          </div>
          <div class="fieldItem wurenxunluo" @click.stop="xunLuo">
            <div class="mask2">{{ $t("Stay Tuned") }}</div>
            <div class="fieldItempadding">
              <div class="fieldItemTitle">
                {{ $t("Unmanned_Security_Service") }}
              </div>
              <div class="fieldItemContent">
                {{ $t("Unmanned_Security_Service_Content") }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="display: flex; justify-content: center;position: relative;align-items: center;padding-bottom: 5rem">
          <div class="fieldItemMore" style="cursor: pointer;" @click="gotoCommerce">{{ $t("View Solution") }}</div>
        </div> -->
      </div>
      <!-- 项目案例 id="case" -->
      <div class="cooperation" id="case">
        <div class="cooperationtitle">
          <span id="case" class="anchor-span">{{
            $t("Cooperation_Case")
          }}</span>
        </div>
        <div class="cooperationcontent">
          {{ $t("Cooperation_Case_Content") }}
        </div>
        <div class="center">
          <div style="padding: 1.875rem 7.5rem 0rem 7.5rem; width: 83.125rem">
            <el-carousel
              :interval="8000"
              type="card"
              style="padding-left: 7.5rem"
              :height="$i18n.locale === 'en' ? '47rem' : '37rem'"
              indicator-position="none"
            >
              <el-carousel-item v-for="item in List" :key="item.title">
                <div style="padding: 1.875rem">
                  <img :src="item.imag" style="width: 100%; height: 22.75rem" />
                  <div class="cooperationItemtitle">
                    {{ item.title }}
                  </div>
                  <div class="cooperationItemcontent">
                    {{ item.content }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div style="display: none" class="mobile-case-wrap">
          <div
            class="mobile-case-item-wrap"
            v-for="item in List"
            :key="item.title"
          >
            <div class="mobile-case-title">
              {{ item.title }}
            </div>
            <div
              class="mobile-case-content-wrap"
              :class="
                $i18n.locale === 'en'
                  ? 'mobile-case-content-wrap-float'
                  : ''
              "
            >
              <img class="mobile-case-img" :src="item.imag" alt="" />
              <div class="mobile-case-content-text">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 行业动态 -->
      <div class="new anchor-span-pc" id="new">
        <div class="newHead">{{ $t("New") }}</div>
        <div class="new-outline">
          <div class="new-content1">
            <ul>
              <li v-for="(item, index) in news1" :key="index">
                <div class="title">
                  <div class="neirong">
                    <a :href="item.url">{{ $t(item.title) }}</a>
                  </div>
                  <div class="date">{{ item.date }}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="picture"></div>
        </div>
        <!-- <div class="new-content">
          <ul>
            <li v-for="(item, index) in news1" :key="index">
              <div class="title">
                <div class="neirong">
                  <a :href="item.url">{{ $t(item.title) }}</a>
                </div>
                <div class="date">{{ item.date }}</div>
              </div>
            </li>
            
          </ul>
        </div>
        <div class="picture"></div> -->
      </div>

      <!-- 联系我们 -->
      <div class="contact-pc">
        <!-- <div>联系我们</div> -->
        <div class="contact-company">联系我们</div>
        <div class="company-name">上海复运智能科技有限公司</div>

        <div class="contact-center">
          <div class="contact-center-left">
            <div style="display: flex;margin-right: 250px;">
              <div class="el-icon-phone-outline contact-icon"></div>
              <div class="contact-item">
                <div style="margin-bottom: 5px;">联系电话</div>
                <div>+86（21）62141070</div>
              </div>

            </div>
            <div style="display: flex;margin-right: 150px;">
              <div class="el-icon-message contact-icon"></div>
              <div class="contact-item">
                <div style="margin-bottom: 5px;">电子邮箱</div>
                <div>sunziyang@forerunnet.com</div>
              </div>

            </div>

            <div style="display: flex;margin-right: 160px;">
              <div class="el-icon-map-location contact-icon"></div>
              <div class="contact-item">
                <div style="margin-bottom: 5px;">联系地址</div>
                <div>上海市普陀区国浩长风城南座516</div>
              </div>

            </div>

            <div style="display: flex;margin-right: 100px;">
              <div class="el-icon-thumb contact-icon"></div>
              <div class="contact-item">
                <div style="margin-bottom: 5px;">官方网址</div>
                <div>https://www.forerunnet.com</div>
              </div>

            </div>
            
          </div>
          <div class="contact-right-wrap">
            <div class="hello">hello</div>
            <div class="huoban">
              {{ $t("Become_Partner") }}
            </div>
            <div class="contactContent contactContent-first">
              <span>{{ $t("Become_Partner_Content_1") }}</span>
            </div>
            <div class="contactContent">
              <span>{{ $t("Become_Partner_Content_2") }}</span>
            </div>

          </div>

        </div>
        <button class="pre-ask" @click.stop="preConsult">预约咨询</button>

        <!-- <div class="contact-wrap">
          
          <div class="contact-input-wrap">
            <div>
              <div class="inputtitle">
                {{ $t("Your_Name") }}
              </div>
              <input class="inputcontent" />
            </div>
            <div>
              <div class="inputtitle">
                {{ $t("Phone_Number") }}
              </div>
              <input class="inputcontent" />
            </div>
            <div>
              <div class="inputtitle">
                {{ $t("Your_Needs") }}
              </div>
              <input class="inputcontent" />
            </div>
          </div>
         

          <img src="../assets/up.png" class="circle" />
          <div class="contact-right-wrap">
            <div class="hello">hello</div>
            <div class="huoban">
              {{ $t("Become_Partner") }}
            </div>
            <div class="contactContent contactContent-first">
              <span>{{ $t("Become_Partner_Content_1") }}</span>
            </div>
            <div class="contactContent">
              <span>{{ $t("Become_Partner_Content_2") }}</span>
            </div>
          </div>
        </div> -->
      </div>
      
      <!-- 生态联盟 id="union" -->
      <Union></Union>

      <!-- 结尾 -->
      <link-copyright></link-copyright>
    </div>
  </div>
  <div v-else>
    <Mobile></Mobile>
  </div>
</template>

<script>
import LinkCopyright from "@/components/LinkCopyright";
import NavBar from '@/components/NavBar'
import Union from '@/components/Union'
import Mobile from "@/components/Mobile.vue";
import VideoPlay from "../components/VideoPlay.vue";
import { animate } from "popmotion";
import jsonFile from "./official_account_articles.json";

export default {
  components: {
    VideoPlay,
    Mobile,
    NavBar,
    Union,
    LinkCopyright,
  },
  data() {
    return {
      activeName: "4",
      posterImgSrc: require("../assets/echarts.png"),
      videoSrc:
        "https://forerun-fsh.cdn.bcebos.com/aecb1327e79fd97878b5c7c0b50256a2.mp4",
      news1: null,
      platform: "",
    };
  },
  computed: {
    images() {
      return [
        {
          url: require("../assets/AI.png"),
          line1: this.$t("Global Leading"),
          line2: "",
          line3: "",
        },
        {
          url: require("../assets/carousel2.png"),
          line1: this.$t("Leading Technology"),
          line2: this.$t("Human Machine Integration"),
          line3: this.$t("Human Vehicle Cloud"),
        },
        {
          url: require("../assets/carousel3.png"),
          line1: this.$t("Full Scene Driven"),
          line2: this.$t("Functional Unmanned Vehicle"),
          line3: this.$t("Support Multiple Services"),
        },
        {
          url: require("../assets/carousel4.png"),
          line1: this.$t("Personalized Customization"),
          line2: this.$t("Hardware Services"),
          line3: this.$t("Industry Differentiation"),
        },
        {
          url: require("../assets/carousel5.png"),
          line1: this.$t("All-weather"),
          line2: this.$t("Human-machine Collaborative"),
          line3: this.$t("Professional Technical Team"),
        },
        {
          url: require("../assets/carousel6.png"),
          line1: this.$t("Industrialization Platform"),
          line2: this.$t("Close Integration"),
          line3: this.$t("Fudan University"),
        },
      ];
    },
    List() {
      return [
        {
          imag: require("../assets/indoor.png"),
          title: this.$t("Case1"),
          content: this.$t("Case1_Content"),
        },
        {
          imag: require("../assets/road.png"),
          title: this.$t("Case2"),
          content: this.$t("Case2_Content"),
        },
        {
          imag: require("../assets/gongjiao.png"),
          title: this.$t("Case3"),
          content: this.$t("Case3_Content"),
        },
        {
          imag: require("../assets/yiyuan.png"),
          title: this.$t("Case4"),
          content: this.$t("Case4_Content"),
        },
        {
          imag: require("../assets/table.png"),
          title: this.$t("Case5"),
          content: this.$t("Case5_Content"),
        },
      ];
    },
    footerList() {
      return [
        {
          title: this.$t("Products_and_Solutions"),
          name: "1",
          list: [
            {
              title: this.$t("CloudControlPlatform"),
            },
            {
              title: this.$t("Unmanned_Delivery_Service"),
            },
            {
              title: this.$t("Unmanned_Transfer_Service"),
            },
            {
              title: this.$t("Unmanned_Cleaning_Service"),
            },
            {
              title: this.$t("Unmanned_Security_Service"),
            },
          ],
        },
        {
          title: this.$t("Media_Center"),
          name: "2",
          list: [
            {
              title: this.$t("News"),
            },
            {
              title: this.$t("Trends"),
            },
          ],
        },
        {
          title: this.$t("Explore_Forerun"),
          name: "3",
          list: [
            {
              title: this.$t("About_Us"),
            },
            {
              title: this.$t("Business_Partner"),
            },
            {
              title: this.$t("Career_Opportunity"),
            },
            {
              title: this.$t("Contact_Us"),
            },
          ],
        },
        {
          title: this.$t("Quick_Links"),
          name: "4",
          list: [
            {
              title: this.$t("CAICV"),
              link: "http://www.caicv.org.cn/index.php/index",
            },
            {
              title: this.$t("Fudan_Lingang_Platform"),
              link: "https://www.fudanlingang.com/col.jsp?id=113",
            },
          ],
        },
      ];
    },
  },
  created() {
    document.addEventListener(
      "DOMContentLoaded",
      function (event) {
        //chrome浏览器

        document.body.style.zoom = "reset";

        document.addEventListener(
          "keydown",
          function (e) {
            if (
              (e.ctrlKey === true || e.metaKey === true) &&
              (e.which === 61 ||
                e.which === 107 ||
                e.which === 173 ||
                e.which === 109 ||
                e.which === 187 ||
                e.which === 189)
            ) {
              e.preventDefault();
            }
          },
          false
        );

        document.addEventListener(
          "mousewhell DOMMouseScroll",
          function (e) {
            if (e.ctrlKey === true || e.metaKey === true) {
              e.preventDefault();
            }
          },
          false
        );
      },
      false
    );
    this.gongZhongHao();
    this.getPlatform();
    // console.log(this.news1)
  },
  methods: {
    getPlatform() {
      let agent = navigator.userAgent.toLowerCase();
      let result = {
        device: (function () {
          if (/windows/.test(agent)) {
            return "windows pc";
          } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
            return "iphone";
          } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
            return "ipad";
          } else if (/android/.test(agent) && /mobile/.test(agent)) {
            return "android";
          } else if (/linux/.test(agent)) {
            return "linux pc";
          } else if (/mac/.test(agent)) {
            return "mac";
          } else {
            return "other";
          }
        })(),
      };
      if (result.device === "iphone" || result.device === "android") {
        this.platform = "mobile";
      } else {
        this.platform = "pc";
      }
      console.log(result, this.platform);
    },
    gongZhongHao() {
      fetch("./official_account_articles.json?t=" + Date.now())
        .then((response) => response.json())
        .then((json) => {
          this.news1 = json;
          console.log(this.news1);
        });
    },
    qingSao() {
      this.$router.push("/solution");
    },
    gotoCommerce() {
      // window.location.href = 'https://hermes-api-dev.foreruncenter.com/commerce-base/index.html'
      window.location.href = 'https://hermes-api.foreruncenter.com/commerce-base/index.html'
    },
    peiSong() {
      let mask = document.querySelector(".mask");
      mask.style.display = "block";
      setTimeout(function () {
        mask.style.display = "none";
      }, 3000);
    },
    jieBo() {
      let mask = document.querySelector(".mask1");
      mask.style.display = "block";
      setTimeout(function () {
        mask.style.display = "none";
      }, 3000);
    },
    xunLuo() {
      let mask = document.querySelector(".mask2");
      mask.style.display = "block";
      setTimeout(function () {
        mask.style.display = "none";
      }, 3000);
    },
    preConsult(){
      this.$router.push('/consultition')
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    rootClick() {
      console.log(11, this.$refs);
      this.$refs.VideoPlay.videoCloseClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor-span-pc {
  padding-top: 5rem !important;
}
/* pc端 */
@media only screen and (min-width: 443px) {
  .nav {
    width: 100%;
    background-image: url(../assets/navbagimag.png);
    display: flex;
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .AI {
    width: 100%;
    // background-image: url(../assets/AI.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  // 轮播图
  #home {
    margin-top: 80px;
  }

  #home .mode {
    height: 860px;
  }

  #home .mode-wrap {
    width: 100%;
  }

  .zhengti {
    position: relative;
  }

  .zi {
    position: absolute;
    top: 350px;
    left: 145px;
    z-index: 10000;
    font-family: Source Han Sans CN-Regular;
  }

  .zi .line1 {
    width: 1150px;
    height: 100px;
    line-height: 50px;
    color: #ffffff;
    font-size: 50px;
    line-height: 70px;
  }

  .zi .line2 {
    width: 800px;
    line-height: 26px;
    color: #ffffff;
    font-size: 25px;
  }

  .zi .line3 {
    width: 720px;
    height: 100px;
    margin-top: 30px;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
  }
  #home .mode-wrap > div:first-child {
    height: 860px !important;
  }
  #home .mode-wrap {
    padding: 0px 0px 0px 0px !important;
  }
  #home .home-mode-wrap > div:first-child {
    height: 36.125rem;
  }

  .home-mode-wrap {
    padding: 0px 0px 0px 0px !important;
  }

  .mode-wrap .el-carousel__item img {
    // position: ;
    width: 100%;
    height: 100%;
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .mode-wrap > div:first-child {
    width: 100%;
    height: 100%;
  }

  .mode-wrap .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .mode-wrap .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .mode-wrap .el-carousel__item.is-animating {
    transition: transform 0.4s ease-in-out;
    width: 100%;
    height: 100%;
  }

  .mode-wrap .el-carousel--horizontal {
    height: 100%;
    overflow-x: hidden;
  }

  .mode-wrap .el-carousel_arrow--left {
    font-size: 2.5rem;
    background-color: rgba(31, 45, 61, 0);
    left: 1.03rem;
    z-index: 10;
    color: #000;
    font-weight: bold;
  }

  .aiTitle-wrap {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0, -50%);
  }

  .aiTitle {
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .aiTitle-left {
    float: left;
  }

  .aiTitle-right {
    float: right;
  }

  .new {
    position: relative;
    width: 100%;
    height: 496px;
    // height: 51.25rem;
    // padding-bottom: 3rem;
    // background-image: url(../assets/cooperationImag.png);
    // background-size: cover;
    // background-repeat: no-repeat;
    background-color: #fff;
    padding-top: 1.5094rem;/* 60px是导航栏高度 */    
    overflow: hidden;
    // margin-top: -80px; 
  }
  .newHead {
    position: absolute;
    top: 70px;
    left: 267px;
    // width: 152px;
    height: 22px;
    // background-color: red;
    font-size: 38px;
    font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
    font-weight: 900;
    color: #3d3d3d;
    line-height: 22px;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .new .new-outline {
    width: 75%;
    position: absolute;
    top: 130px;
    left: 170px;
    display: flex;
    justify-content: space-around;
    // border: 1px solid black;
  }
  .new .new-outline .new-content1 {
    // position: absolute;
    // top: 121px;
    // left: 219px;
    width: 720px;
    height: 344px;
    // background-color: palevioletred;
    border-top: 2px solid gray;
  }

  .lang-menu-wrap {
    color: #fff;
    font-size: 1rem;
    position: absolute;
    right: 6vh;
    top: 50%;
    transform: translate(0, -50%);
  }

  .el-dropdown-menu {
    top: 4.3rem !important;
  }

  .nav-dropdown-menu {
    display: none;
  }

  .banquan {
    // width: 38.625rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.1875rem;
    text-align: center;
  }

  .time {
    padding-top: 1rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    line-height: 1.1875rem;
  }

  .new-content1 ul li .title .neirong::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .new-content1 ul li .title .date {
    position: absolute;
    right: 10px;
    top: 35px;
    font-size: 14px;
  }
  .new .picture {
    // display: block;
    // position:absolute;
    // top:121px;
    // left:1089px;
    width: 450px;
    height: 290px;
    background: url(../assets/new-picture.png) no-repeat;
    background-size: 450px 290px;
  }
  .phone {
    padding-top: 1rem;
    // height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.75rem;
  }

  .shuline {
    width: 0rem;
    height: 16.125rem;
    opacity: 1;
    border: 0.0625rem solid #ffffff;
  }


  .gongzhonghao {
    height: 2rem;
    font-size: 1.125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.3125rem;
  }

  .Forerun {
    padding-top: 1.125rem;
    width: 8rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.1875rem;
  }

  .detail {
    width: 100%;
    // height: 28.5rem;
    background: #060e21;
  }

  

  .fieldItempadding {
    position: relative;
    width: 150px;
    height: 320px;
    padding-top: 3.125rem;
    padding-left: 1.875rem;
  }

  .fieldItem {
    width: 17.75rem;
    height: 24.375rem;

    background-size: cover;
    background-repeat: no-repeat;
    /* border: #FFFFFF 1px dashed; */
  }

  .fieldcontent {
    width: 62.5rem;
    padding-top: 2.125rem;
    /* text-align: center; */
    // height: 31px;
    font-size: 1.375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 2.5rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .fieldtitle {
    padding-top: 6.5rem;
    text-align: center;
    // height: 4.1875rem;
    font-size: 3rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 3.5rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .field {
    width: 100%;
    // height: 50rem;
    background-image: url(../assets/filedimag.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .newmodetext2 {
    padding-top: 2.5rem;
    // height: 2.625rem;
    width: 22.25rem;
    font-size: 1.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 2.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .systemtext2 {
    padding-top: 0.5rem;
    // height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #000000;
    line-height: 1.625rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .modebutton {
    width: 11.25rem;
    height: 4rem;
    background: #0a1739;
    border-radius: 0.875rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    float: right;
    margin-top: -2.5rem;
  }

  .systemtext {
    width: 62.5rem;
    padding-top: 1.125rem;

    height: 7.75rem;
    font-size: 1.375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 2.25rem;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .mode-text-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .newmodetext {
    text-align: center;
    width: 74.5rem;
    // height: 4.1875rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 3.5rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .modebutton-wrap {
    width: 100%;
  }

  .mode {
    width: 100%;
    // height: 56.25rem;
    background-image: url(../assets/mdoe.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    /* position: relative;
        animation-name: example;
        animation-duration: 3s;
        animation-delay: 2s;
        animation-fill-mode: backwards; */
  }

  .mode-wrap > div:first-child {
    width: 100%;
    height: 20.125rem;
  }

  @keyframes example {
    from {
      top: 0px;
    }

    to {
      top: 12.5rem;
    }
  }

  .aiTitle-wrap {
    width: 45rem;
  }

  .aiTitle {
    font-size: 3rem;
    line-height: 4.375rem;
  }

  .AI {
    height: 50.625rem;
  }

  .navTitleItem {
    display: flex;
    align-items: center;
    width: 8.125rem;
    font-size: 1rem;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .navTitle {
    display: flex;
    justify-content: space-between;
    padding-left: 6.25rem;
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
  }

  .nav {
    height: 5rem;
  }

  .logoImag {
    width: 10rem;
    height: 4.375rem;
    padding-left: 4.625rem;
    padding-top: 0.3125rem;
    /* background-size: cover; */
  }

  .mode-wrap {
    // padding: 3.75rem 6.25rem 3.75rem 6.25rem;
    width: 100%;
  }

  .systemtext2 {
    width: 20.625rem;
  }

  .example > div:nth-child(2),
  .example > div:nth-child(3) {
    margin-left: 10px;
  }

  .field-img-wrap {
    padding: 3.25rem 5.5rem;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
  }

  .wurenjiebo,
  .wurenxunluo {
    margin-left: 10px;
  }

  
  .contact-wrap {
    padding: 130px 80px 100px 80px;
    display: flex;
    justify-content: space-between;
  }

  .contact-info {
    border: 1px solid red;
  }

  .contact-input-wrap {
    display: flex;
    justify-content: space-between;
    width: 930px;
  }


 

  

  

  .rightLink {
    position: fixed;
    top: 9.375rem;
    right: 0;
    margin-top: 15.625rem;
    background-image: url(../assets/Rectangle.png);
    width: 6.25rem;
    height: 11.25rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .rightLink img {
    width: 3.75rem;
    height: 3.75rem;
  }

  .rightLink img:first-child {
    padding-top: 1.25rem;
  }

  .rightLink img:last-child {
    padding-bottom: 1.25rem;
  }

  .rightLink > div {
    width: 56px;
    height: 0px;
    opacity: 1;
    border: 1px solid #ffffff;
  }

  .circle {
    padding-top: 13.75rem;
    padding-right: 6.25rem;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.25rem;
  }

  .inputcontent {
    background: #0a1632;
    border-bottom: 0.0625rem solid #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #fff;
    width: 15.25rem;
  }

  .inputtitle {
    padding-top: 13.75rem;
    // width: 4rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  input:focus {
    outline: none;
  }

  .contactContent {
    text-align: right;
    // width: 15rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .contactContent-first {
    padding-top: 84px;
  }

  .huoban {
    text-align: right;
    height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.75rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .hello {
    text-align: right;
    height: 6.4375rem;
    font-size: 5.1875rem;
    font-family: Rubik One-Regular, Rubik One;
    font-weight: bold;
    color: #ffffff;
    line-height: 6.0625rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .contact-pc {
    width: 100%;
    height: 31.25rem;
    background-image: url(../assets/contact.png);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

  }
  .contact-company {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 45px;
    margin-left: 60px;
    margin-bottom: 65px;
  }
  .company-name{
    font-size: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 60px;
    
  }
  .pre-ask {
    margin-top: 260px;
    margin-left: 60px;
    width: 150px;
    height: 40px;
    border-radius: 15px;
    font-size: 18px;
    cursor: pointer;
  }
  .pre-ask:hover {
    background-color:#0052D9;
    color: #ffffff;

  }

  .contact-center {
    position: relative;
  }

  .contact-center-left {
    position: absolute;
    top: 20px;
    left: 60px;
    width: 970px;
    height: 200px;
    // border: 1px solid red;
    display: flex;
    flex-wrap: wrap;
    
  }

  .contact-icon {
    font-size: 40px;
    margin-top: 10px;
    color: #b4b4b4;
  }

  .contact-item {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    color: #ffffff;

  }
  .contact-right-wrap {
    position: absolute;
    left: 1550px;
  }


  .cooperationItemtitle {
    width: 23.125rem;
    // height: 2.125rem;
    margin-bottom: 0.3rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 1.75rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .cooperationItemcontent {
    width: 24.8125rem;
    height: 4.125rem;
    font-size: 0.9rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 1rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  [class*=" el-icon-"],
  [class^="el-icon-"] {
    font-weight: bold;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-carousel__arrow--left {
    font-size: 2.5rem;
    background-color: rgba(31, 45, 61, 0);
    left: -60px;
    z-index: 10;
    color: #000;
    font-weight: bold;
  }

  .el-carousel__arrow--right {
    font-size: 2.5rem;
    background-color: rgba(31, 45, 61, 0);
    right: 1.875rem;
    z-index: 10;
    color: #000;
    font-weight: bold;
  }

  .el-carousel__item {
    width: 27.875rem;
  }

  .el-carousel__item--card.is-active {
    background: #f6f6f6;
  }

  .el-carousel__item--card {
    background: #eaeaea;
  }

  .cooperationcontent {
    padding-top: 1rem;
    text-align: center;

    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang HK-Light, PingFang HK;
    font-weight: 300;
    color: #000000;
    line-height: 1.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .cooperationtitle {
    padding-top: 5.125rem;
    text-align: center;

    height: 4.1875rem;
    font-size: 3rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 3.5rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .cooperation {
    width: 100%;
    // height: 51.25rem;
    padding-bottom: 3rem;
    background-image: url(../assets/cooperationImag.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .new {
    position: relative;
    width: 100%;
    height: 496px;
    // height: 51.25rem;
    // padding-bottom: 3rem;
    // background-image: url(../assets/cooperationImag.png);
    // background-size: cover;
    // background-repeat: no-repeat;
    background-color: #fff;
    // padding-top: 80px;/* 60px是导航栏高度 */ 
    // margin-top: -80px;   
    // overflow: hidden;
  }

  .newHead {
    position: absolute;
    top: 6rem;
    left: 223px;
    // width: 152px;
    height: 22px;
    // background-color: red;
    font-size: 38px;
    font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
    font-weight: 900;
    color: #3d3d3d;
    line-height: 22px;
    background-clip: text;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  // .new-content {
  //   position: absolute;
  //   top: 121px;
  //   left: 219px;
  //   width: 720px;
  //   height: 344px;
  //   // background-color: palevioletred;
  //   border-top: 2px solid gray;
  // }

  .new-content1 ul {
    position: absolute;
    top: 50px;
    // left: 30px;
  }

  .new-content1 ul li {
    position: relative;
    margin-bottom: 10px;

    // height: 30px;
  }

  .new-content1 ul li .title {
    position: relative;
    // height: 20px;
    width: 720px;
    height: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 20px;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .new-content1 ul li .title .neirong {
    position: absolute;
    top: 20px;
    left: 0;
  }

  .new-content1 ul li .title .neirong a {
    display: inline-block;
    width: 600px;
    height: 40px;
    line-height: 40px;
    // background-color: black;
    white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis;
  }

  .new-content1 ul li .title .neirong::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-right: 5px;
  }

  .new-content1 ul li .title .date {
    position: absolute;
    right: 10px;
    top: 35px;
    font-size: 14px;
  }

  .new .picture {
    // display: block;
    // position: absolute;
    // top: 121px;
    // left: 1089px;
    width: 450px;
    height: 290px;
    background: url(../assets/new-picture.png) no-repeat;
    background-size: 450px 290px;
  }

  .wurenpeisong {
    position: relative;
    background-image: url(../assets/wurenpeisong.png);
  }

  .wurenpeisong .mask {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4) no-repeat;
    text-align: center;
    line-height: 24.375rem;
    font-size: 20px;
    color: #fff;
    font-weight: bolder;
  }

  .wurenjiebo {
    position: relative;
    background-image: url(../assets/wurenjiebo.png);
  }

  .wurenjiebo .mask1 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4) no-repeat;
    text-align: center;
    line-height: 24.375rem;
    font-size: 20px;
    color: #fff;
    font-weight: bolder;
  }

  .wurenhuanwei {
    background-image: url(../assets/wurenhuanwei.png);
  }

  .wurenxunluo {
    position: relative;
    background-image: url(../assets/wurenxunluo.png);
  }

  .wurenxunluo .mask2 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4) no-repeat;
    text-align: center;
    line-height: 24.375rem;
    font-size: 20px;
    color: #fff;
    font-weight: bolder;
  }

  .fieldItemContent {
    width: 11.5rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.1875rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .fieldItemTitle {
    width: 8rem;
    // height: 2.8125rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 2.375rem;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .fieldItemMore {
    // position: absolute;
    // bottom: 4px;
    // left: 80%;
    padding: 0 30px;
    min-width: 235px;
    height: 48px;
    background: #0052d9;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
  }

  .mode-wrap {
    padding: 3.75rem 6.25rem 3.75rem 6.25rem;
    width: 100%;
  }














}
</style>
