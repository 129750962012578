<template>
    <div class="nav">
        <div>
            <img class="logoImag" src="../assets/FORERUNLogo.png" />
        </div>
        <div class="navTitle">
            <div class="navTitleItem" v-for="item in navItems" :key="item.id" @click="navTo(item.path, item.id)">
                {{ $t(item.text) }}
            </div>
            <!-- 外部链接作为特例处理 -->
            <a class="navTitleItem" style="width:12rem;"
                href="https://hermes-api.foreruncenter.com/commerce-base/index.html">
                {{ $t("Commerce") }}
            </a>
        </div>

        <!-- 下拉菜单 -->
        <el-dropdown class="lang-menu-wrap" trigger="click" @command="changeLanguage">
            <span class="el-dropdown-link">
                {{ language.title
                }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in languageList" :key="index" :command="index">
                    {{ item.title }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            navItems: [
                { path: '/home', id: 'home', text: 'Index' },
                { path: '/home', id: 'core', text: 'Technology' },
                { path: '/home', id: 'application', text: 'Solutions' },
                { path: '/home', id: 'case', text: 'Projects' },
                { path: '/home', id: 'new', text: 'New' },
                { path: '/home', id: 'union', text: 'Collaboration' }
            ],
            language: {
                value: "ZH-CN",
                title: "中文",
            },
            languageList: [
                {
                    value: "ZH-CN",
                    title: "中文",
                },
                {
                    value: "EN-US",
                    title: "English",
                },
            ],
        };
    },
    methods: {
        navTo(path, sectionId) {
            this.$router.push({ path });
            this.$nextTick(() => {
                this.scrollToSection(sectionId);
            });
        },
        scrollToSection(sectionId) {
            // 获取导航条元素
            const navbar = document.querySelector('.nav');
            // 获取导航条的实际高度
            const navbarHeightPx = navbar.offsetHeight;

            // 找到目标元素
            const section = document.getElementById(sectionId);
            if (section) {
                // 计算目标元素的顶部相对于视窗顶部的距离
                const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;

                // 减去导航条的高度并滚动到该位置
                const offsetPosition = sectionTop - navbarHeightPx;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth' // 平滑滚动
                });
            }
        },
        changeLanguage(index) {
            this.language = this.languageList[index];
            switch (this.language.value) {
                case "EN-US":
                    this.$i18n.locale = "en";
                    this.carouselHeight = "52rem";
                    break;
                case "ZH-CN":
                    this.$i18n.locale = "cn";
                    this.carouselHeight = "37rem";
                    break;
            }
        },

    }
};
</script>

<style scoped>
/* pc端 */
@media only screen and (min-width: 443px) {
    .nav {
        width: 100%;
        height: 5rem;
        background-image: url(../assets/navbagimag.png);
        display: flex;
        position: fixed;
        top: 0;
        z-index: 1000;
    }

    .navTitleItem {
        display: flex;
        align-items: center;
        width: 8.125rem;
        font-size: 1rem;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.1875rem;
        background-clip: text;
        -webkit-background-clip: text;
    }

    .navTitle {
        display: flex;
        justify-content: space-between;
        padding-left: 6.25rem;
    }

    .lang-menu-wrap {
        color: #fff;
        font-size: 1rem;
        position: absolute;
        right: 6vh;
        top: 50%;
        transform: translate(0, -50%);
    }

    .el-dropdown-menu {
        top: 4.3rem !important;
    }
}
</style>