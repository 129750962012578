<template>
  <div class="content" @click="rootClick">

    <!-- 导航条 -->
    <div class="nav">
      <div>
        <img class="logoImag" src="@/assets/FORERUNLogo.png">
      </div>
      <div class="navTitle">
        <div class="navTitleItem" @click="scrollToTop">
          <!-- 首页 -->
          {{ $t('Index') }}
        </div>
        <div class="navTitleItem" @click="scrollTomode">
          {{ $t('Technology') }}
        </div>
        <div class="navTitleItem" @click="scrollTofield">
          {{ $t('Solutions') }}
        </div>
        <div class="navTitleItem" @click="scrollTocooperation">
          {{ $t('Projects') }}
        </div>
        <div class="navTitleItem" @click="scrollTounion">
          {{ $t('Collaboration') }}
        </div>
      </div>
      <el-dropdown class="lang-menu-wrap" trigger="click" @command="changeLanguage">
        <span class="el-dropdown-link">
          {{ language.title }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in languageList" :key="index" :command="index">
            {{ item.title }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown class="nav-dropdown-menu" trigger="click">
        <span class="el-dropdown-link">
          <i class="el-icon-menu"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><a href="#home">{{ $t('Index') }}</a></el-dropdown-item>
          <el-dropdown-item><a href="#core">{{ $t('Technology') }}</a></el-dropdown-item>
          <el-dropdown-item><a href="#application">{{ $t('Solutions') }}</a></el-dropdown-item>
          <el-dropdown-item><a href="#case">{{ $t('Projects') }}</a></el-dropdown-item>
          <el-dropdown-item><a href="#union">{{ $t('Collaboration') }}</a></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 首屏 + fixed客服 -->
    <div id="home" class="AI">
      <!-- <div class="aiTitle-wrap">
        <div class="aiTitle aiTitle-left">
          {{ $t('TitleA_1') }}
        </div>
        <div class="aiTitle aiTitle-right">
          {{ $t('TitleA_2') }}
        </div>
      </div> -->
      <div class="mode1">
          <div class="mode-wrap home-mode-wrap">
            <div class="block">
              <el-carousel
                trigger="click"
                height="65vw"
                class="carousel-first"
              >
                <el-carousel-item v-for="item in images" :key="item.id">
                  <div class="zhengti">
                    <div class="zi">
                      <div class="line1">{{ item.line1 }}</div>
                      <div class="line2">{{ item.line2 }}</div>
                      <div class="line3">{{ item.line3 }}</div>
                    </div>
                    <div class="tupian"><img :src="item.url" alt="" /></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>

      <!-- 扫码+客服 -->
      <a href="#QRcode">
        <div class="rightLink">
          <img src="@/assets/erweima.png">
          <div></div>
          <img src="@/assets/erji.png">
        </div>
      </a>

    </div>
    <!-- 核心技术 id="core" -->
    <div class="mode">
      <div class="mode-wrap">
        <div>
          <div class="mode-text-wrap">
            <div class="newmodetext">
              <span id="core" class="anchor-span">{{ $t('TitleB') }}</span>
            </div>
            <div class="center">
              <div class="systemtext">
                {{ $t('ContentB') }}
              </div>
            </div>
            <div class="modebutton-wrap">
              <button class="modebutton">
                {{ $t('LearnMore') }}
              </button>
            </div>
          </div>
        </div>
        <div class="example" style="display: flex;justify-content: space-between;">
          <div>
            <img src="@/assets/cheluxitong.png" style="width: 22.25rem;height: 22.25rem;">
            <div class="modetext-wrap">
              <div class="newmodetext2">
                {{ $t('MultiSensorFVRSystem') }}
              </div>
              <div class="systemtext2">
                {{ $t('MultiSource_content') }}
              </div>
            </div>

          </div>
          <div>
            <VideoPlay ref="VideoPlay" class="VideoPlay" :posterImgSrc="posterImgSrc" :videoSrc="videoSrc"
              style="width: 22.25rem;height: 22.375rem;"></VideoPlay>
            <!-- <img src="@/assets/echarts.png" style="width: 22.25rem;height: 22.375rem;"> -->
            <div class="modetext-wrap">
              <div class="newmodetext2">
                {{ $t('CloudControlPlatform') }}
              </div>
              <div class="systemtext2">
                {{ $t('CloudControlPlatform_content') }}
              </div>
            </div>
          </div>

          <div>
            <img src="@/assets/yunjisuan.png" style="width: 22.25rem;height: 22.25rem;">
            <div class="modetext-wrap">
              <div class="newmodetext2">
                {{ $t('Hermes_AI_Cloud_Computing') }}
              </div>
              <div class="systemtext2">
                {{ $t('Hermes_AI_Cloud_Computing_content') }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 应用领域 id="application" -->
    <div class="field">
      <div class="fieldtitle">
        <span id="application" class="anchor-span">{{ $t('ApplicationFields') }}</span>
      </div>
      <div class="center">
        <div class="fieldcontent">
          {{ $t('ApplicationFields_Content') }}
        </div>
      </div>
      <div class="field-img-wrap">
        <div class="fieldItem wurenpeisong">
          <div class="fieldItempadding">
            <div class="fieldItemTitle">
              {{ $t('Unmanned_Delivery_Service') }}
            </div>
            <div class="fieldItemContent">
              {{ $t('Unmanned_Delivery_Service_Content') }}
            </div>
          </div>
        </div>
        <div class="fieldItem wurenjiebo">
          <div class="fieldItempadding">
            <div class="fieldItemTitle">
              {{ $t('Unmanned_Transfer_Service') }}
            </div>
            <div class="fieldItemContent">
              {{ $t('Unmanned_Transfer_Service_Content') }}
            </div>
          </div>
        </div>
        <div class="fieldItem wurenhuanwei" @click.stop="qingSao">
          <div class="fieldItempadding">
            <div class="fieldItemTitle">
              {{ $t('Unmanned_Cleaning_Service') }}
            </div>
            <div class="fieldItemContent">
              {{ $t('Unmanned_Cleaning_Service_Content') }}
            </div>
            <div class="fieldItemMore">{{ $t("View Solution") }}</div>
          </div>
        </div>
        <div class="fieldItem wurenxunluo">
          <div class="fieldItempadding">
            <div class="fieldItemTitle">
              {{ $t('Unmanned_Security_Service') }}
            </div>
            <div class="fieldItemContent">
              {{ $t('Unmanned_Security_Service_Content') }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 项目案例 id="case" -->
    <div class="cooperation ">
      <div class="cooperationtitle">
        <span id="case" class="anchor-span">{{ $t('Cooperation_Case') }}</span>
      </div>
      <div class="cooperationcontent">
        {{ $t('Cooperation_Case_Content') }}
      </div>
      <div class="center">
        <div style="padding: 1.875rem 7.5rem 0rem 7.5rem;width: 83.125rem;">
          <el-carousel :interval="8000" type="card" style="padding-left: 7.5rem;" :height="carouselHeight"
            indicator-position="none">
            <el-carousel-item v-for="item in List" :key="item.title">
              <div style="padding: 1.875rem;">
                <img :src='item.imag' style="width: 100%;height: 22.75rem;">
                <div class="cooperationItemtitle">
                  {{ item.title }}
                </div>
                <div class="cooperationItemcontent">
                  {{ item.content }}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div style="display: none;" class="mobile-case-wrap">
        <div class="mobile-case-item-wrap" v-for="item in List" :key="item.title">
          <div class="mobile-case-title">
            {{ item.title }}
          </div>
          <div class="mobile-case-content-wrap"
            :class="language.value === 'EN-US' ? 'mobile-case-content-wrap-float' : ''">
            <img class="mobile-case-img" :src="item.imag" alt="">
            <div class="mobile-case-content-text">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact-wrap">
        <div class="contact-input-wrap">
          <div>
            <div class="inputtitle">
              {{ $t('Your_Name') }}
            </div>
            <input class="inputcontent"></input>
          </div>
          <div>
            <div class="inputtitle">
              {{ $t('Phone_Number') }}
            </div>
            <input class="inputcontent"></input>
          </div>
          <div>
            <div class="inputtitle">
              {{ $t('Your_Needs') }}
            </div>
            <input class="inputcontent"></input>
          </div>
        </div>
        <!-- <div class="circle">
  
          </div> -->

        <img src="@/assets/up.png" class="circle"></img>
        <div class="contact-right-wrap">
          <div class="hello">
            hello
          </div>
          <div class="huoban">
            {{ $t('Become_Partner') }}
          </div>
          <div class="contactContent contactContent-first">
            <span>{{ $t('Become_Partner_Content_1') }}</span>
          </div>
          <div class="contactContent">
            <span>{{ $t('Become_Partner_Content_2') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 生态联盟 id="union" -->
    <div class="union">
      <div class="union-wrap">
        <div class="unionTitle">
          <span id="union" class="anchor-span">{{ $t('Cooperative_Alliance') }}</span>
        </div>
        <div class="union-member-wrap">
          <div>
            <img class="union-img union-1" src="@/assets/huiyuandanwei.png"></imag>
            <div class="union-1-text unionItemText">{{ $t('Member_Company') }}</div>
          </div>
          <div>
            <img class="union-img union-2" src="@/assets/faqidanwei.png"></imag>
            <div class="union-2-text unionItemText">{{ $t('Founding_Unit') }}</div>
          </div>
          <div>
            <img class="union-img union-3" src="@/assets/weihuihuizhang.png"></imag>
            <div class="union-3-text unionItemText">{{ $t('President_of_ICV') }}</div>
          </div>
          <div>
            <img class="union-img union-4" src="@/assets/lishidanwei.png"></imag>
            <div class="union-4-text unionItemText">{{ $t('Founder_and_Director_Unit') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 结尾 -->
    <div class="detail">
      <div class="detail-wrap">
        <div class="QRcode-wrap" id="QRcode">
          <div class="gongzhonghao" style="width:12rem;">{{ $t('Official_Account') }}</div>
          <img class="QRcode" src="@/assets/erweima.png">
          <div class="Forerun">复运科技Forerun</div>
        </div>

        <div class="detail-info-wrap">
          <div class="detail-product-wrap">
            <div class="gongzhonghao">
              {{ $t('Products_and_Solutions') }}
            </div>
            <div class="detailItemtext detailItemtext-1">
              {{ $t('CloudControlPlatform') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Unmanned_Delivery_Service') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Unmanned_Transfer_Service') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Unmanned_Cleaning_Service') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Unmanned_Security_Service') }}
            </div>
          </div>
          <div class="detail-news-wrap">
            <div class="gongzhonghao">
              {{ $t('Media_Center') }}
            </div>
            <div class="detailItemtext">
              {{ $t('News') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Trends') }}
            </div>

          </div>
          <div class="detail-contact-wrap">
            <div class="gongzhonghao">
              {{ $t('Explore_Forerun') }}
            </div>
            <div class="detailItemtext">
              {{ $t('About_Us') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Business_Partner') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Career_Opportunity') }}
            </div>
            <div class="detailItemtext">
              {{ $t('Contact_Us') }}
            </div>
          </div>
          <div class="detail-link-wrap">
            <div>{{ $t('Quick_Links') }}</div>
            <div class="detailItemtext"><a href="http://www.caicv.org.cn/index.php/index">{{ $t('CAICV') }}</a>
            </div>
            <div class="detailItemtext"><a href="https://www.fudanlingang.com/col.jsp?id=113">{{
              $t('Fudan_Lingang_Platform') }}</a>
            </div>
          </div>
        </div>

        <div class="shuline"></div>

        <div class="detail-phone-wrap">
          <div class="phone">
            {{ $t('Contact_Information') }}
          </div>
          <div class="phone">021-62141070</div>
          <div class="time">09:00-12:00 13:00-18:00</div>
          <div class="time">{{ $t('Monday_to_Friday') }}</div>
          <div>
            <div>{{ $t('Customer_Service') }}</div>
          </div>
        </div>
      </div>

      <!-- 手机端 -->
      <div class="mobile-detail-wrap">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="item in footerList" :key="item.name" :title="item.title" :name="item.name">
            <div v-for="it in item.list" :key="it.title">
              <div v-if="it.link"><a :href="it.link">{{ it.title }}</a></div>
              <div v-else>{{ it.title }}</div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
 
      <div class="mobile-QRcoe-wrap" style="display:none;">
        <div class="mobile-QRcode-wrap">
          <div class="mobile-gongzhonghao">{{ $t('Official_Account') }}</div>
          <img class="mobile-QRcode" src="@/assets/erweima.png">
          <div class="mobile-Forerun">复运科技Forerun</div>
        </div>

        <div class="mobile-detail-phone-wrap">
          <div class="mobile-phone">
            {{ $t('Contact_Information') }}
          </div>
          <div class="mobile-phone">021-62141070</div>
          <div class="mobile-time">09:00-12:00 13:00-18:00</div>
          <div class="mobile-time">{{ $t('Monday_to_Friday') }}</div>
          <div>
            {{ $t('Customer_Service') }}
          </div>
        </div>
      </div>

      <div class="detail-copyright-wrap">
        <img src="@/assets/FORERUNLogo.png">
        <div class="banquan">
          {{ $t('Copyright_1') }}
          <a href="https://beian.miit.gov.cn">{{ $t('Copyright_2') }}</a>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import VideoPlay from '@/components/VideoPlay.vue';
import {
  animate
} from 'popmotion'

export default {
  components: {
    VideoPlay
  },
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      activeName: '4',
      carouselHeight: '37rem',
      posterImgSrc: require('@/assets/echarts.png'),
      videoSrc: 'https://forerun-fsh.cdn.bcebos.com/aecb1327e79fd97878b5c7c0b50256a2.mp4',
      language: {
        value: "ZH-CN",
        title: "中文"
      },
      languageList: [
        {
          value: "ZH-CN",
          title: "中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ]
    }
  },
  computed: {
    images() {
      return [
        {
          url: require("../assets/AI.png"),
          line1: this.$t("Global Leading"),
          line2: "",
          line3: "",
        },
        {
          url: require("../assets/carousel2.png"),
          line1: this.$t("Leading Technology"),
          line2: this.$t("Human Machine Integration"),
          line3: this.$t("Human Vehicle Cloud"),
        },
        {
          url: require("../assets/carousel3.png"),
          line1: this.$t("Full Scene Driven"),
          line2: this.$t("Functional Unmanned Vehicle"),
          line3: this.$t("Support Multiple Services"),
        },
        {
          url: require("../assets/carousel4.png"),
          line1: this.$t("Personalized Customization"),
          line2: this.$t("Hardware Services"),
          line3: this.$t("Industry Differentiation"),
        },
        {
          url: require("../assets/carousel5.png"),
          line1: this.$t("All-weather"),
          line2: this.$t("Human-machine Collaborative"),
          line3: this.$t("Professional Technical Team"),
        },
        {
          url: require("../assets/carousel6.png"),
          line1: this.$t("Industrialization Platform"),
          line2: this.$t("Close Integration"),
          line3: this.$t("Fudan University"),
        },
      ];
    },
    List() {
      return [{
        imag: require('@/assets/indoor.png'),
        title: this.$t('Case1'),
        content: this.$t('Case1_Content')
      },
      {
        imag: require('@/assets/road.png'),
        title: this.$t('Case2'),
        content: this.$t('Case2_Content')
      },
      {
        imag: require('@/assets/gongjiao.png'),
        title: this.$t('Case3'),
        content: this.$t('Case3_Content')
      },
      {
        imag: require('@/assets/yiyuan.png'),
        title: this.$t('Case4'),
        content: this.$t('Case4_Content')
      }
      ]
    },
    footerList() {
      return [
        {
          title: this.$t('Products_and_Solutions'),
          name: '1',
          list: [
            {
              title: this.$t('CloudControlPlatform')
            },
            {
              title: this.$t('Unmanned_Delivery_Service')
            },
            {
              title: this.$t('Unmanned_Transfer_Service')
            },
            {
              title: this.$t('Unmanned_Cleaning_Service')
            },
            {
              title: this.$t('Unmanned_Security_Service')
            }
          ]
        },
        {
          title: this.$t('Media_Center'),
          name: '2',
          list: [
            {
              title: this.$t('News')
            },
            {
              title: this.$t('Trends')
            }
          ]
        },
        {
          title: this.$t('Explore_Forerun'),
          name: '3',
          list: [
            {
              title: this.$t('About_Us')
            },
            {
              title: this.$t('Business_Partner')
            },
            {
              title: this.$t('Career_Opportunity')
            },
            {
              title: this.$t('Contact_Us')
            }
          ]
        },
        {
          title: this.$t('Quick_Links'),
          name: '4',
          list: [
            {
              title: this.$t('CAICV'),
              link: 'http://www.caicv.org.cn/index.php/index'
            },
            {
              title: this.$t('Fudan_Lingang_Platform'),
              link: 'https://www.fudanlingang.com/col.jsp?id=113'
            }
          ]
        }
      ]
    },
  },
  created() {
    document.addEventListener('DOMContentLoaded', function (event) {
      //chrome浏览器

      document.body.style.zoom = 'reset';

      document.addEventListener('keydown', function (e) {
        if ((e.ctrlKey === true || e.metaKey === true) &&

          (e.which === 61 || e.which === 107 || e.which === 173 || e.which === 109

            ||
            e.which === 187 || e.which === 189)) {
          e.preventDefault();

        }

      }, false);

      document.addEventListener('mousewhell DOMMouseScroll', function (e) {
        if (e.ctrlKey === true || e.metaKey === true) {
          e.preventDefault();

        }

      }, false);

    }, false);
  },
  methods: {
    qingSao() {
      this.$router.push("/solution");
    },
    scrollTounion() {
      // 1.1 返回文档在垂直方向已滚动的像素值
      const now = window.scrollY
      // 1.2 目标位置（文章信息区域的高度）
      let dist = document.querySelector('.union').offsetHeight + 3500

      animate({
        from: now, // 当前的位置
        to: dist, // 目标位置
        onUpdate: latest => window.scrollTo(0, latest)
      })
    },
    scrollTocooperation() {
      // 1.1 返回文档在垂直方向已滚动的像素值
      const now = window.scrollY
      // 1.2 目标位置（文章信息区域的高度）
      let dist = document.querySelector('.cooperation').offsetHeight + 1660

      animate({
        from: now, // 当前的位置
        to: dist, // 目标位置
        onUpdate: latest => window.scrollTo(0, latest)
      })
    },
    scrollTofield() {
      // 1.1 返回文档在垂直方向已滚动的像素值
      const now = window.scrollY
      // 1.2 目标位置（文章信息区域的高度）
      let dist = document.querySelector('.field').offsetHeight + 840

      animate({
        from: now, // 当前的位置
        to: dist, // 目标位置
        onUpdate: latest => window.scrollTo(0, latest)
      })
    },
    scrollToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    scrollTomode() {

      // 1.1 返回文档在垂直方向已滚动的像素值
      const now = window.scrollY
      // 1.2 目标位置（文章信息区域的高度）
      let dist = document.querySelector('.mode').offsetHeight - 170

      animate({
        from: now, // 当前的位置
        to: dist, // 目标位置
        onUpdate: latest => window.scrollTo(0, latest)
      })
    },
    // startHacking() {
    //   this.$notify({
    //     title: 'It works!',
    //     type: 'success',
    //     message: 'We\'ve laid the ground work for you. It\'s time for you to build something epic!',
    //     duration: 5000
    //   })
    // },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    rootClick() {
      this.$refs.VideoPlay.videoCloseClick()
    },
    changeLanguage(index) {
      this.language = this.languageList[index];
      switch (this.language.value) {
        case "EN-US":
          this.$i18n.locale = "en";
          this.carouselHeight = '52rem'
          break;
        case "ZH-CN":
          this.$i18n.locale = "cn";
          this.carouselHeight = '37rem'
          break;
      }
    },
  }
}
</script>
  
<style lang="scss">
.nav {
  width: 100%;
  background-image: url(@/assets/navbagimag.png);
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.AI {
  width: 100%;
  background-image: url(@/assets/AI.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.aiTitle-wrap {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
}

.aiTitle {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-background-clip: text;
}

.aiTitle-left {
  float: left;
}

.aiTitle-right {
  float: right;
}


/* pc端 */
@media only screen and (min-width: 443px) {
  .mobile-detail-wrap {
    display: none;
  }

  .lang-menu-wrap {
    color: #fff;
    font-size: 1rem;
    position: absolute;
    right: 6vh;
    top: 50%;
    transform: translate(0, -50%);
  }

  .el-dropdown-menu {
    top: 4.3rem !important;
  }

  .nav-dropdown-menu {
    display: none;
  }

  .banquan {
    // width: 38.625rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.1875rem;
    text-align: center;
  }

  .time {
    padding-top: 1rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 1.1875rem;
  }

  .phone {
    padding-top: 1rem;
    // height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.75rem;
  }

  .shuline {
    width: 0rem;
    height: 16.125rem;
    opacity: 1;
    border: 0.0625rem solid #FFFFFF;
  }

  .detailItemtext {
    padding-top: 1rem;
    // height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.66);
    line-height: 1.1875rem;
  }

  .gongzhonghao {

    height: 2rem;
    font-size: 1.125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.3125rem;
  }

  .Forerun {
    padding-top: 1.125rem;
    width: 8rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.1875rem;
  }

  .detail {
    width: 100%;
    // height: 28.5rem;
    background: #060E21;
  }

  .unionItemText {
    text-align: center;
    padding-top: 2.125rem;
    height: 1.5625rem;
    font-size: 1.125rem;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #000000;
    line-height: 1.3125rem;
    -webkit-background-clip: text;
  }

  .unionTitle {
    // width: 8rem;
    height: 2.8125rem;
    font-size: 2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 2.375rem;
    -webkit-background-clip: text;
  }

  .union {
    width: 100%;
    // height: 17.75rem;
    background-image: url(@/assets/cooperationImag.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .circle {
    padding-top: 13.75rem;
    padding-right: 6.25rem;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.25rem;
  }

  .inputcontent {
    background: #0A1632;
    border-bottom: 0.0625rem solid #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #fff;
    width: 15.25rem;
  }

  .inputtitle {
    padding-top: 13.75rem;
    // width: 4rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 1.1875rem;
    -webkit-background-clip: text;

  }

  input:focus {
    outline: none;
  }

  .contactContent {
    text-align: right;
    // width: 15rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.1875rem;
    -webkit-background-clip: text;
  }

  .contactContent-first {
    padding-top: 84px;
  }

  .huoban {
    text-align: right;
    height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.75rem;
    -webkit-background-clip: text;
  }

  .hello {
    text-align: right;
    height: 6.4375rem;
    font-size: 5.1875rem;
    font-family: Rubik One-Regular, Rubik One;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 6.0625rem;
    -webkit-background-clip: text;

  }

  .contact {
    width: 100%;
    height: 31.25rem;
    background-image: url(@/assets/contact.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cooperationItemtitle {
    width: 23.125rem;
    // height: 2.125rem;
    margin-bottom: 0.3rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 1.75rem;
    -webkit-background-clip: text;

  }

  .cooperationItemcontent {
    width: 24.8125rem;
    height: 4.125rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 1.5rem;
    -webkit-background-clip: text;

  }



  [class*=" el-icon-"],
  [class^=el-icon-] {

    font-weight: bold;

  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-carousel__arrow--left {
    font-size: 2.5rem;
    background-color: rgba(31, 45, 61, 0);
    // left: -60px;
    z-index: 10;
    color: #000;
    font-weight: bold;
  }

  .el-carousel__arrow--right {
    font-size: 2.5rem;
    background-color: rgba(31, 45, 61, 0);
    right: 1.875rem;
    z-index: 10;
    color: #000;
    font-weight: bold;
  }

  .el-carousel__item {
    width: 27.875rem;

  }

  .el-carousel__item--card.is-active {
    background: #F6F6F6;
  }

  .el-carousel__item--card {
    background: #EAEAEA;
  }

  .el-carousel__item:nth-child(2n) {

    /* width: 372px;
        height: 460px; */
  }

  .el-carousel__item:nth-child(2n+1) {
    /* background-color: #d3dce6; */
    /* width: 446px;
        height: 550px; */
  }

  .cooperationcontent {
    padding-top: 1rem;
    text-align: center;

    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang HK-Light, PingFang HK;
    font-weight: 300;
    color: #000000;
    line-height: 1.1875rem;
    -webkit-background-clip: text;

  }

  .cooperationtitle {
    padding-top: 5.125rem;
    text-align: center;

    height: 4.1875rem;
    font-size: 3rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 3.5rem;
    -webkit-background-clip: text;


  }

  .cooperation {
    width: 100%;
    // height: 51.25rem;
    padding-bottom: 3rem;
    background-image: url(@/assets/cooperationImag.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .wurenpeisong {
    background-image: url(@/assets/wurenpeisong.png);
  }

  .wurenjiebo {
    background-image: url(@/assets/wurenjiebo.png);
  }

  .wurenhuanwei {
    background-image: url(@/assets/wurenhuanwei.png);
  }

  .wurenxunluo {
    background-image: url(@/assets/wurenxunluo.png);
  }

  .fieldItemContent {
    width: 11.5rem;
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.1875rem;
    -webkit-background-clip: text;

  }

  .fieldItemTitle {
    width: 8rem;
    // height: 2.8125rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2.375rem;
    -webkit-background-clip: text;

  }

  .fieldItempadding {
    padding-top: 3.125rem;
    padding-left: 1.875rem;
  }

  .fieldItem {
    width: 17.75rem;
    height: 24.375rem;

    background-size: cover;
    background-repeat: no-repeat;
    /* border: #FFFFFF 1px dashed; */
  }

  .fieldcontent {
    width: 62.5rem;
    padding-top: 2.125rem;
    /* text-align: center; */
    // height: 31px;
    font-size: 1.375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2.5rem;
    -webkit-background-clip: text;

  }

  .fieldtitle {
    padding-top: 6.5rem;
    text-align: center;
    // height: 4.1875rem;
    font-size: 3rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 3.5rem;
    -webkit-background-clip: text;

  }

  .field {
    width: 100%;
    // height: 50rem;
    background-image: url(@/assets/filedimag.png);
    background-size: cover;
    background-repeat: no-repeat;

  }

  .newmodetext2 {
    padding-top: 2.5rem;
    // height: 2.625rem;
    width: 22.25rem;
    font-size: 1.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 2.1875rem;
    -webkit-background-clip: text;

  }

  .systemtext2 {

    padding-top: 0.5rem;
    // height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #000000;
    line-height: 1.625rem;
    -webkit-background-clip: text;



  }

  .modebutton {
    width: 11.25rem;
    height: 4rem;
    background: #0A1739;
    border-radius: 0.875rem;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    float: right;
    margin-top: -2.5rem;
  }

  .systemtext {

    width: 62.5rem;
    padding-top: 1.125rem;

    height: 7.75rem;
    font-size: 1.375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 2.25rem;
    -webkit-background-clip: text;


  }

  .mode-text-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newmodetext {
    text-align: center;
    width: 74.5rem;
    // height: 4.1875rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 3.5rem;
    -webkit-background-clip: text;

  }

  .modebutton-wrap {
    width: 100%;
  }

  .mode {
    width: 100%;
    // height: 56.25rem;
    background-image: url(@/assets/mdoe.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    /* position: relative;
        animation-name: example;
        animation-duration: 3s;
        animation-delay: 2s;
        animation-fill-mode: backwards; */
  }

  .mode-wrap>div:first-child {
    width: 100%;
    height: 20.125rem;
  }

  @keyframes example {
    from {
      top: 0px;
    }

    to {
      top: 12.5rem;
    }
  }

  .aiTitle-wrap {
    width: 45rem;
  }

  .aiTitle {
    font-size: 3rem;
    line-height: 4.375rem;
  }

  .AI {
    height: 50.625rem;
  }

  .navTitleItem {
    display: flex;
    align-items: center;
    width: 8.125rem;
    font-size: 1rem;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.1875rem;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
  }

  .navTitle {
    display: flex;
    justify-content: space-between;
    padding-left: 6.25rem;
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
  }

  img {
    /* width: 100%;
        height: 100%;
        background: rgb(226, 9, 9); */
  }

  .nav {
    height: 5rem;
  }

  .logoImag {
    width: 10rem;
    height: 4.375rem;
    padding-left: 4.625rem;
    padding-top: 0.3125rem;
    /* background-size: cover; */
  }

  .mode-wrap {
    padding: 3.75rem 6.25rem 3.75rem 6.25rem;
    width: 100%;
  }

  .systemtext2 {
    width: 20.625rem;
  }

  .example>div:nth-child(2),
  .example>div:nth-child(3) {
    margin-left: 10px;
  }

  .field-img-wrap {
    padding: 5.25rem 5.5rem;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
  }

  .wurenjiebo,
  .wurenxunluo {
    margin-left: 10px;
  }

  .contact-wrap {
    padding: 130px 80px 100px 80px;
    display: flex;
    justify-content: space-between;
  }

  .contact-input-wrap {
    display: flex;
    justify-content: space-between;
    width: 930px;
  }

  .contact-right-wrap {
    // width: 240px;
  }

  .union-wrap {
    padding: 2rem 3.75rem 4.125rem 3.75rem;
  }

  .union-member-wrap {
    display: flex;
    justify-content: space-between;
  }

  .union-1 {
    width: 24.75rem;
    height: 2.5rem;
    padding-top: 2.125rem;
  }

  .union-1-text {
    width: 24.75rem;
    margin-top: 0.375rem;
  }

  .union-2 {
    width: 16.5rem;
    height: 2.75rem;
    padding-top: 2.25rem;
  }

  .union-2-text {
    width: 16.5rem;
  }

  .union-3 {
    width: 21rem;
    height: 2.75rem;
    padding-top: 2.125rem;
  }

  .union-3-text {
    width: 21rem;
  }

  .union-4 {
    width: 17.25rem;
    height: 2.75rem;
    padding-top: 2.25rem;
  }

  .union-4-text {
    width: 17.25rem;
  }

  .detail-wrap {
    padding: 4.75rem 5rem 0rem 4.75rem;
    display: flex;
    justify-content: space-between;
  }

  .QRcode {
    width: 8rem;
    height: 8rem;
    padding-top: 1.625rem;
  }

  .detail-info-wrap {
    display: flex;
    flex: auto;
    margin: 0 3vw;
  }

  .detail-product-wrap {
    /* width: 17.125rem; */
    flex: auto;
    min-width: 170px;
  }

  .detailItemtext-1 {
    // margin-top: 0.25rem;
  }

  .detail-news-wrap {
    /* width:13.75rem; */
    flex: auto;
    min-width: 140px;
    padding: 0 2rem;
  }

  .detail-news-wrap>.detailItemtext:first {
    margin-top: 0.25rem;
  }

  .detail-contact-wrap {
    /* width: 13.75rem; */
    flex: auto;
    min-width: 168px;
    padding: 0 2rem;
  }

  .detail-contact-wrap>.detailItemtext:first {
    margin-top: 0.25rem;
  }

  .detail-link-wrap {
    flex: auto;
    min-width: 225px;
  }

  .detail-link-wrap a {
    color: rgba(255, 255, 255, 0.66);
  }

  .detail-link-wrap a:hover {
    color: #1a73e8;
  }

  .detail-link-wrap div:first-child {
    height: 2rem;
    font-size: 1.125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.3125rem;
  }

  .detail-link-wrap div:nth-of-type(2) {
    // margin-top: 0.25rem;
  }

  .detail-phone-wrap {
    text-align: center;
    // width: 11.625rem;
    width: 18.625rem;
    margin-left: 2vw;
  }

  .detail-phone-wrap>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 11.625rem;
      height: 2.5rem;
      background-color: #fff;
      border-radius: 0.5rem;
      margin-top: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .detail-copyright-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4.75rem;
    padding-right: 4.75rem;
    padding-top: 2.5rem;
  }

  .detail-copyright-wrap>img {
    width: 8.125rem;
    height: 3.625rem;
  }

  .banquan a {
    color: #fff;
    text-decoration: none
  }

  .rightLink {
    position: fixed;
    top: 9.375rem;
    right: 0;
    margin-top: 15.625rem;
    background-image: url(@/assets/Rectangle.png);
    width: 6.25rem;
    height: 11.25rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .rightLink img {
    width: 3.75rem;
    height: 3.75rem;
  }

  .rightLink img:first-child {
    padding-top: 1.25rem;
  }

  .rightLink img:last-child {
    padding-bottom: 1.25rem;
  }

  .rightLink>div {
    width: 56px;
    height: 0px;
    opacity: 1;
    border: 1px solid #FFFFFF;
  }
}

/* 手机端 */
@media only screen and (max-width: 443px) {
  .nav {
    height: 5vh;
  }

  .logoImag {
    width: 14vh;
    height: 6.375vh;
    position: absolute;
    left: 2vh;
    top: 50%;
    transform: translate(0, -50%);
  }
  // 手机端轮播图
  .mode1 {
    height: 65vw;
  }

  .mode1 .mode-wrap {
    height: 65vw;
  }
  .mode1 .block {
    height: 65vw;
  }
  
  .el-carousel--horizontal {
    height: 65vw;
  }
  .zhengti {
    position: relative;
  }

  .zi {
    position: absolute;
    top: 60px;
    left: 15px;
    z-index: 10000;
    font-family: Source Han Sans CN-Regular;
  }
  .zi .line1 {
    // width: 1150px;
    // height: 100px;
    // line-height: 50px;
    color: #ffffff;
    // font-size: 50px;
    // line-height: 70px;
  }
  .zi .line2 {
    // width: 800px;
    // line-height: 26px;
    color: #ffffff;
    // font-size: 25px;
  }
  .zi .line3 {
    // width: 720px;
    // height: 100px;
    // margin-top: 30px;
    color: #ffffff;
    // font-size: 18px;
    // line-height: 30px;
  }
  .tupian img {
    height: 65vw;
  }
  .el-carousel__indicators--horizontal {
    left: 58%;
    width: 300px;
  }
  




  .navTitle {
    display: none;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
  }

  .el-icon-menu {
    color: #fff;
  }

  .lang-menu-wrap {
    position: absolute;
    right: 6vh;
    top: 50%;
    transform: translate(0, -55%);
  }

  .nav-dropdown-menu {
    position: absolute;
    right: 2vh;
    top: 50%;
    transform: translate(0, -50%);
  }

  .AI {
    height: 65vw;
    background-position: 0 3vh;
  }

  .aiTitle-wrap {
    width: 15rem;
  }

  .aiTitle {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .mode {
    width: 100%;
    /* height: 65vw; */
    background-image: url(@/assets/mdoe.png);
    background-size: cover;
    background-repeat: no-repeat;

    .VideoPlay {
      width: 40vw !important;
      height: 40vw !important;
    }
  }

  .mode-text-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .newmodetext {
    text-align: center;
    font-size: 1rem;
    // height: 1rem;
    line-height: 1rem;
    color: #000;
    margin-bottom: 1rem;
    margin: 0.75rem 1rem;
    margin-top: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
  }

  .systemtext {
    line-height: 1rem;
    font-size: 0.75rem;
    color: #000;
    margin: 0 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .modebutton-wrap {
    /* display: flex;
        justify-content: end; */
    width: 100%;
  }

  .modebutton {
    font-size: 0.75rem;
    color: #fff;
    background-color: #0A1739;
    padding: 0.5rem 0;
    border-radius: 0.75rem;
    margin-right: 2rem;
    width: 5rem;
    margin-top: 1rem;
    float: right;
  }

  .example {
    color: #000;
    flex-direction: column;
    padding: 2rem;
    padding-top: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .example img {
    width: 40vw !important;
    height: 40vw !important;
  }

  .example>div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .example>div:nth-child(2) {
    flex-direction: row-reverse;
  }

  .example>div>div {
    /* margin: 1rem; */
  }

  .modetext-wrap {
    position: relative;
    width: 40Vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .newmodetext2 {
    // position: absolute;
    // top: 30%;
    // transform: translate(0, -50%);
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: 1vw;
  }

  .modetext-wrap .systemtext2 {
    // position: absolute;
    // top: 50%;
    // transform: translate(0, -50%);
  }

  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url(@/assets/filedimag.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .fieldtitle {
    font-size: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #fff;
    margin: 0.75rem 1rem;
    margin-top: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
  }

  .fieldcontent {
    line-height: 1rem;
    font-size: 0.75rem;
    color: #fff;
    margin: 0 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .wurenpeisong {
    background-image: url(@/assets/wurenpeisong.png);
    background-size: cover;
  }

  .wurenjiebo {
    background-image: url(@/assets/wurenjiebo.png);
    background-size: cover;
  }

  .wurenhuanwei {
    background-image: url(@/assets/wurenhuanwei.png);
    background-size: cover;
  }

  .wurenxunluo {
    background-image: url(@/assets/wurenxunluo.png);
    background-size: cover;
  }

  .field-img-wrap {
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.75rem 0;
  }

  .fieldItem {
    width: 35vw;
    height: 45vw;
    margin: 0.5rem;
  }

  .fieldItempadding {
    position: relative;
    margin: 4vw;
    text-align: left;
    color: #fff;
    font-family: PingFang SC-Medium, PingFang SC;
    height: 160px;
  }

  .fieldItemTitle {
    font-size: 1rem;
    margin-bottom: 0.15rem;
    font-weight: bold;
  }

  .fieldItemContent {
    font-size: 0.75rem;
  }

  // 清扫部分，点击查看详情
  .fieldItemMore {
    position: absolute;
    bottom: 10px;
    left: 33px;
    width: 80px;
    height: 26px;
    background: #0052d9;
    color: #ffffff;
    border-radius: 10px;
    text-align: center;
    line-height: 26px;
    font-size: 12px;
  }


  .cooperation {
    width: 100%;
    background-image: url(@/assets/cooperationImag.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cooperationtitle {
    font-size: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #000;
    margin: 0.75rem 1rem;
    margin-top: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
  }

  .cooperationcontent {
    text-align: center;
    line-height: 1rem;
    font-size: 0.75rem;
    color: #000;
    margin: 0 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .contact {
    width: 100%;
    background-image: url(@/assets/contact.png);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    /* padding: 1rem; */
    background-position: right;
  }

  .contact-wrap {
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  .circle {
    width: 8vw;
    height: 8vw;
    margin-top: 8.75rem;
  }

  .contact-input-wrap {
    display: flex;
    flex-direction: column;
    padding: 1rem
  }

  .contact-input-wrap>div {
    margin-bottom: 1rem;
  }

  .contact-input-wrap>div:last-child {
    margin-bottom: 0;
  }

  .inputcontent {
    background: transparent;
    border-bottom: 0.0625rem solid #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #fff;
    width: 30vw;
  }

  .contact-right-wrap {
    text-align: right;
    padding: 2rem 1rem 0.8rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hello {
    font-size: 8vw;
    font-weight: bold;
    margin-bottom: 4vw 0 2vw 0;
    display: inline-block;
  }

  .huoban {
    font-size: 4vw;
    margin-bottom: 3vw;
  }

  .union {
    width: 100%;
    background-image: url(@/assets/cooperationImag.png);
    background-size: cover;
    background-repeat: no-repeat;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .unionTitle {
    font-size: 5vw;
    font-weight: bold;
  }

  .union-wrap {
    display: flex;
    flex-direction: column;
  }

  .unionTitle {
    padding: 1rem;
  }

  .union-1 {
    height: 9vw;
  }

  .union-1-text,
  .union-3-text {
    line-height: 8vw;
  }

  .union-2 {
    height: 9vw;
  }

  .union-3 {
    height: 9vw;
  }

  .union-4 {
    height: 9vw;
  }

  .union-member-wrap {
    margin-bottom: 1rem;
  }

  .union-member-wrap,
  .union-member-wrap>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6vw;
  }

  .union-member-wrap>div:first-child,
  .union-member-wrap>div:nth-child(3) {
    margin-bottom: 3vw;
  }

  .union-member-wrap>div:last-child {
    margin-bottom: 0;
  }

  .unionItemText {
    font-size: 3vw;
    font-family: PingFang SC-Medium, PingFang SC;
  }

  .detail {
    width: 100%;
    background: #060E21;
  }

  .QRcode {
    width: 5vw;
    height: 5vw;
  }

  .detail-wrap {
    /* display: flex;
        color: #fff;
        padding: 1rem 0;
        margin: 0 2rem;
        font-size: 0.75rem;
        border-bottom: 1px solid #797979;
        flex-direction: column; */
    display: none;
  }

  /* .detail-info-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
      } */
  .QRcode-wrap,
  .detail-phone-wrap {
    display: none;
  }

  /* .detail-info-wrap{
        font-size: 0.75rem;
        font-family: PingFang SC-Medium, PingFang SC;
        line-height: 1.5rem;
      } */
  /* .gongzhonghao {
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 2rem;
      } */
  .mobile-QRcoe-wrap {
    display: flex !important;
    justify-content: space-between;
    margin: 0 2rem;
    padding: 1rem 0;
    color: #fff;
    font-family: PingFang SC-Medium, PingFang SC;
    line-height: 1.5rem;
    font-size: 0.75rem;
  }

  .mobile-QRcode {
    width: 20vw;
    margin: 1rem 0;
  }

  .mobile-gongzhonghao,
  .mobile-Forerun {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .mobile-phone {
    font-size: 1rem;
    font-weight: bold;
  }

  .mobile-detail-phone-wrap>div {
    margin-bottom: 0.5rem;
  }

  .mobile-detail-phone-wrap>div:last-child {
    background-color: #fff;
    color: #000;
    border-radius: 0.5rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 0;
    /* width: 5rem; */
  }

  .detail-copyright-wrap {
    padding: 0 2rem;
    display: flex;
  }

  .detail-copyright-wrap img {
    /* width: 30vw; */
    display: none;
  }

  .banquan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }

  .cooperation .center {
    display: none;
  }

  .mobile-case-wrap {
    padding: 1rem;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #000;
  }

  .mobile-case-wrap,
  .mobile-case-item-wrap {
    display: flex !important;
    flex-direction: column;
  }

  .mobile-case-item-wrap {
    margin-bottom: 1rem;
  }

  .mobile-case-item-wrap:last-child {
    margin-bottom: 0;
  }

  .mobile-case-content-wrap {
    display: flex;
  }

  .mobile-case-img {
    width: 30vw;
    height: 30vw;
    margin-right: 1rem;
    // float: left;
  }

  .mobile-case-content-wrap-float {
    display: block;

    .mobile-case-img {
      float: left;
    }
  }

  .mobile-case-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .mobile-case-content-text {
    font-size: 0.75rem;
  }

  .rightLink {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-image: url(@/assets/Rectangle.png);
    background-size: contain;
  }

  .rightLink img {
    width: 15vw;
  }

  .rightLink>img:first-child {
    margin: 1rem;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #fff;
  }

  .rightLink>img:last-child {
    margin: 1rem;
    margin-top: 0.5rem;
  }

  .anchor-span {
    padding-top: 5vh;
  }

  .mobile-detail-wrap {
    padding: 10px;
  }

  .mobile-detail-wrap .el-collapse {
    border-top: none;
  }

  .mobile-detail-wrap .el-collapse-item__header {
    background-color: #060E21;
    color: #fff;
    font-size: 14px;
    height: 42px;
    line-height: 42px;
    font-weight: 600;
  }

  .mobile-detail-wrap .el-collapse-item__content {
    background-color: #060E21;
  }

  .mobile-detail-wrap .el-collapse-item__content>div {
    font-size: 14px;
    padding-left: 12px;
    line-height: 24px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.66);
  }

  .mobile-detail-wrap .el-collapse-item__content a {
    color: rgba(255, 255, 255, 0.66);
  }

  .mobile-detail-wrap .el-collapse-item__content a:hover {
    color: #1a73e8;
  }

  .rightLink {
    display: none;
  }
}
</style>