import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/init.css'
import '../src/assets/init.scss'
import App from './App.vue'
import i18n from "./i18n"
import router from './router'

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  i18n,
  render: h => h(App),
  router
}).$mount('#app')