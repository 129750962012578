<template>
    <div class="detail">
        <div class="detail-wrap">
            <div class="QRcode-wrap" id="QRcode">
                <div class="gongzhonghao" style="width: 12rem">
                    {{ $t("Official_Account") }}
                </div>
                <img class="QRcode" src="@/assets/erweima.png" />
                <div class="Forerun">复运科技Forerun</div>
            </div>

            <div class="detail-info-wrap">
                <div class="detail-product-wrap">
                    <div class="gongzhonghao">
                        {{ $t("Products_and_Solutions") }}
                    </div>
                    <div class="detailItemtext detailItemtext-1">
                        {{ $t("CloudControlPlatform") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Unmanned_Delivery_Service") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Unmanned_Transfer_Service") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Unmanned_Cleaning_Service") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Unmanned_Security_Service") }}
                    </div>
                </div>
                <div class="detail-news-wrap">
                    <div class="gongzhonghao">
                        {{ $t("Media_Center") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("News") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Trends") }}
                    </div>
                </div>
                <div class="detail-contact-wrap">
                    <div class="gongzhonghao">
                        {{ $t("Explore_Forerun") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("About_Us") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Business_Partner") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Career_Opportunity") }}
                    </div>
                    <div class="detailItemtext">
                        {{ $t("Contact_Us") }}
                    </div>
                </div>
                <div class="detail-link-wrap">
                    <div>{{ $t("Quick_Links") }}</div>
                    <div class="detailItemtext">
                        <a href="http://www.caicv.org.cn/index.php/index">{{
                        $t("CAICV")
                    }}</a>
                    </div>
                    <div class="detailItemtext">
                        <a href="https://www.fudanlingang.com/col.jsp?id=113">{{
                            $t("Fudan_Lingang_Platform")
                        }}</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- 版权 -->
        <div class="detail-copyright-wrap">
            <img src="@/assets/FORERUNLogo.png" />
            <div class="banquan">
                {{ $t("Copyright_1") }}
                <a href="https://beian.miit.gov.cn">{{ $t("Copyright_2") }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LinkCopyright',
    data() {
        return {

        };
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
.QRcode {
    width: 8rem;
    height: 8rem;
    padding-top: 1.625rem;
  }
 .detail-wrap {
    padding: 4.75rem 5rem 0rem 4.75rem;
    display: flex;
    justify-content: space-between;
  }
.detailItemtext {
    padding-top: 1rem;
    font-size: 1rem;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.66);
    line-height: 1.1875rem;
}

.detail-info-wrap {
    display: flex;
    flex: auto;
    margin: 0 3vw;
  }

  .detail-product-wrap {
    /* width: 17.125rem; */
    flex: auto;
    min-width: 170px;
  }

  .detail-news-wrap {
    /* width:13.75rem; */
    flex: auto;
    min-width: 140px;
    padding: 0 2rem;
  }

  .detail-news-wrap > .detailItemtext:first {
    margin-top: 0.25rem;
  }

  .detail-contact-wrap {
    /* width: 13.75rem; */
    flex: auto;
    min-width: 168px;
    padding: 0 2rem;
  }

  .detail-contact-wrap > .detailItemtext:first {
    margin-top: 0.25rem;
  }

  .detail-link-wrap {
    flex: auto;
    min-width: 225px;
  }

  .detail-link-wrap a {
    color: rgba(255, 255, 255, 0.66);
  }

  .detail-link-wrap a:hover {
    color: #1a73e8;
  }

  .detail-link-wrap div:first-child {
    height: 2rem;
    font-size: 1.125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.3125rem;
  }

  .detail-phone-wrap {
    text-align: center;
    width: 18.625rem;
    margin-left: 2vw;
  }

  .detail-phone-wrap > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 11.625rem;
      height: 2.5rem;
      background-color: #fff;
      border-radius: 0.5rem;
      margin-top: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .detail-copyright-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4.75rem;
    padding-right: 4.75rem;
    padding-top: 2.5rem;
  }

  .detail-copyright-wrap > img {
    width: 8.125rem;
    height: 3.625rem;
  }

  .banquan a {
    color: #fff;
    text-decoration: none;
  }


</style>